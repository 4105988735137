import React, { useState, useEffect, useRef } from 'react';

import { Typography, Box } from '@mui/material';
import axios from 'axios';

import { InputLabel, Select, MenuItem } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ApartmentIcon from '@mui/icons-material/Apartment';


import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';

import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridEditInputCell,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,

  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { arSD } from '@mui/x-data-grid/locales';

import CircularProgress from '@mui/material/CircularProgress';
import { Container } from '@mui/material';

import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import ReplayIcon from '@mui/icons-material/Replay';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { CacheProvider } from "@emotion/react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import CardContent from '@mui/material/CardContent';
import SettingsIcon from '@mui/icons-material/Settings';
import useAuthentication_etab from './useAuthentication_etab';
import LinearProgress from '@mui/material/LinearProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import XLSX from 'sheetjs-style';
import Footer from './Footer';
import Header from './Header';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './config'; // Adjust the path if necessary
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import createCache from "@emotion/cache";

import '../containers/amirifont.css'; // Import the font CSS file
import Divider from '@mui/material/Divider';
import LockIcon from '@mui/icons-material/Lock';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import useAuthentication_logged from './useAuthentication_logged';

import './MyToolbarStyles.css'; // Adjust the path as necessary

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

const defaultColor = "#4caf50"; // Default green color
const hoverColor = "#004d40"; // Lighter green when hovered
const focusColor = "#81c784"; // Even lighter green when focused

const themetextfieldnew = createTheme({
  palette: {
    primary: {
      main: defaultColor
    },
    secondary: {
      main: defaultColor
    }
  },
  direction: 'rtl',
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: hoverColor,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: focusColor,
          },
        },
        notchedOutline: {
          borderColor: defaultColor,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: focusColor,
          },
          "&:hover": {
            color: hoverColor,
          },
          color: defaultColor, // Default label color
        },
      },
    },
  },
  fieldset: {
    fontFamily: '"Changa", sans-serif',
    fontSize: 45,
  },
});


const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});




const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({

  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


async function loginUser(email, MotDePass) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, MotDePass: MotDePass }),
    });

    if (!response.ok) {
      throw new Error('Invalid credentials');
    }

    const data = await response.json();
    const token = data.token;
    return token;
  } catch (error) {
    console.error('Error logging in:', error.message);
    return null;
  }
}


const StyledBox = styled('div')(({ theme }) => ({
  height: 400,
  width: '100%',
  '& .MuiDataGrid-cell--editable': {
    backgroundColor: theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
    color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
  },
}));

let rowsdata = [];
let promiseTimeout;
function validateName(username) {

  const alphabetRegex = /^[a-zA-Z\s]+$/;
  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      const isValid = alphabetRegex.test(username);
      resolve(isValid ? null : 'Username must contain only alphabets (a to z).');
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

let promiseTimeoutar;
function validateNamear(username) {
  const arabicAlphabetRegex = /^[\u0600-\u06FF\s]+$/;

  return new Promise((resolve) => {
    promiseTimeoutar = setTimeout(() => {
      const isValid = arabicAlphabetRegex.test(username);
      resolve(isValid ? null : 'Username must contain only Arabic alphabets.');
    }, Math.random() * 500 + 100); // simulate network latency
  });
}


const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEditName(params) {
  return <NameEditInputCell {...params} />;
}


const apiUrl = `${API_BASE_URL}/api/getetab_byid_iddfep`;

function CustomToolbar({ setRows, setRowModesModel, handleExportExcel, handleAddRecord, customTextField_genNomUser, customTextField_genpw }) {
  return (


    <GridToolbarContainer className="custom-toolbar" >


      <GridToolbarColumnsButton className="custom-toolbar" />
      <GridToolbarFilterButton className="custom-toolbar" />
      <GridToolbarDensitySelector className="custom-toolbar" />
      <Button className="custom-toolbar" onClick={handleExportExcel} color="primary">
        تنزيل كملف إكسال
      </Button>


      <GridToolbarExport className="custom-toolbar" />

      <GridToolbarQuickFilter
        className="custom-toolbar"

      />

      {/* Container for customTextField_genNomUser with spacing */}
      <div style={{ marginRight: '10px' }}>
        {customTextField_genNomUser}
      </div>

      {/* Container for customTextField_genpw with spacing */}
      <div style={{ marginLeft: '10px' }}>
        {customTextField_genpw}
      </div>


    </GridToolbarContainer>
  );
}



let options_Wly = [];
let Wlylists = [];

function SettingMfep() {

  const authlogged = useAuthentication_logged(); // Get values from useAuthentication_logged
  const authCandData = useAuthentication_etab(); // Always call the hook at the top level, but we will conditionally use its data
  const [authCand, setAuthCand] = useState(null); // Create a state for authCand

  // Function to get the value from authlogged
  const getValue = (key) => {
    try {
      return authlogged?.[key] ?? null;
    } catch (error) {
      console.error(`Error fetching ${key}:`, error);
      return null;
    }
  };

  const IDCandidat = getValue("IDCandidat");
  const IDEncadrement = getValue("IDEncadrement");
  const IDetablissement = getValue("IDetablissement");
  const IDDFEP = getValue("IDDFEP");
  const IDNature_etsF = getValue("IDNature_etsF");
  const IDUtilisateur = getValue("IDUtilisateur");
  const UtilisateurNom = getValue("UtilisateurNom");
  const LoginTracesNom = getValue("LoginTracesNom");
  const IDapis_userslist = getValue("IDapis_userslist");
  const loadingauth = getValue("loadingauth");

  // Function to get the value from authCand if it exists
  const getValuecand = (key) => {
    try {
      return authCand?.[key] ?? null;
    } catch (error) {
      console.error(`Error fetching ${key}:`, error);
      return null;
    }
  };

  let NumSem = getValuecand("NumSem");
  let loadingauthcand = getValuecand("loadingauth");
  let Singup_active = getValuecand("Singup_active");
  let download_cert_scol_active = getValuecand("download_cert_scol_active");
  let EditingInfo_active = getValuecand("EditingInfo_active");
  let Eval_notes_active = getValuecand("Eval_notes_active");
  let download_cert_inscription_active = getValuecand("download_cert_inscription_active");
  let Singup_active_Dfep = getValuecand("Singup_active_Dfep");
  let download_cert_scol_active_Dfep = getValuecand("download_cert_scol_active_Dfep");
  let EditingInfo_active_Dfep = getValuecand("EditingInfo_active_Dfep");
  let Eval_notes_active_Dfep = getValuecand("Eval_notes_active_Dfep");
  let download_cert_inscription_active_Dfep = getValuecand("download_cert_inscription_active_Dfep");
  let Singup_active_Mfep = getValuecand("Singup_active_Mfep");
  let download_cert_scol_active_Mfep = getValuecand("download_cert_scol_active_Mfep");
  let EditingInfo_active_Mfep = getValuecand("EditingInfo_active_Mfep");
  let Eval_notes_active_Mfep = getValuecand("Eval_notes_active_Mfep");
  let download_cert_inscription_active_Mfep = getValuecand("download_cert_inscription_active_Mfep");
  let crypted_id = getValuecand("crypted_id");

  useEffect(() => {
    // Check if IDCandidat is valid and authCandData is not loading
    if (IDetablissement !== null && IDetablissement !== undefined && Number(IDetablissement) > 0 && !authCandData["loadingauth"]) {
      // Deep comparison of authCand and authCandData to prevent unnecessary state updates
      if (JSON.stringify(authCand) !== JSON.stringify(authCandData)) {

        setAuthCand(authCandData); // Update authCand state when IDCandidat is valid and authCand has changed
      }
    }
  }, [IDetablissement, authCandData, authCand]); // Trigger useEffect when IDCandidat, authCandData, or authCand changes





  // Inherit the theme from the docs site (dark/light mode)
  const existingTheme = useTheme();

  const theme_datagrid = React.useMemo(
    () =>
      createTheme({}, arSD, existingTheme, {
        direction: 'rtl',
      }),
    [existingTheme],
  );


  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [options_Wlylist, setoptions_Wlylist] = React.useState([]);

  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [isAdding, setIsAdding] = React.useState(false);
  const [deletedRowId, setDeletedRowId] = useState(null);
  const [idforsave, setidforsave] = useState(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
  const [editedValue, setEditedValue] = useState('');

  const [snackbar, setSnackbar] = React.useState(null);
  const [snackbar_all, setSnackbar_all] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const handleCloseSnackbar_all = () => setSnackbar_all(null);

  const [valuegenpw, setvaluegenpw] = useState('');
  const [valuegenpw_nomUser, setvaluegenpw_nomUser] = useState('');


  const [Eval_notes_active_enable, setEval_notes_active_enable] = useState(false);
  const [EditingInfo_active_etab_enable, setEditingInfo_active_etab_enable] = useState(false);
  const [download_cert_scol_active_etab_enable, setdownload_cert_scol_active_etab_enable] = useState(false);
  const [download_cert_inscription_active_etab_enable, setdownload_cert_inscription_active_etab_enable] = useState();


  const [deleted_NomAr, setDeleted_NomAr] = useState(null);
  const [deleted_PrenomAr, setDeleted_PrenomAr] = useState(null);

  const [privilege_users_data, setprivilege_users_data] = useState([]);
  const [selectedID_privilege_users, setselectedID_privilege_users] = useState('');

  const [libelleArabe_etab, setlibelleArabe_etab] = useState();
  const [Nom_etab, setNom_etab] = useState();

  const editedValueRef = useRef(editedValue);


  const [singtoggleclicked, setsingtoggleclicked] = useState(false);
  const [edittoggleclicked, setedittoggleclicked] = useState(false);
  const [downtoggleclicked, setdowntoggleclicked] = useState(false);
  const [notestoggleclicked, setnotestoggleclicked] = useState(false);
  const [down_inscriptiontoggleclicked, setdown_inscriptiontoggleclicked] = useState(false);






  let numrun = 0;
  const [allowfetch, setallowfetch] = useState(true);

  useEffect(() => {






    const fetchData = async () => {


      try {
        if (!allowfetch) {

          return;
        }


        const response = await fetch(`${API_BASE_URL}/api/getetab_byid/${IDetablissement}`
          , {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
            }
          });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();

        ///alert(JSON.stringify(data));
        /// setValuesFromData_getinfo(data.data);
        setValuesFromData_getinfo(data.data, data.data_dfep, data.data_mfep);

      } catch (error) {
        console.error(error);
        // Handle error, e.g., show an error message to the user
      } finally {
        setLoading(false);
        setallowfetch(false);
        numrun++;
      }


    };

    if (allowfetch) {
      fetchData();
    }

  }, [allowfetch]);


  const [Singup_active_etab, setSingup_active_etab] = useState();
  const [download_cert_scol_active_etab, setdownload_cert_scol_active_etab] = useState();
  const [EditingInfo_active_etab, setEditingInfo_active_etab] = useState();
  const [download_cert_inscription_active_etab, setdownload_cert_inscription_active_etab] = useState();

  const [Eval_notes_active_disabled, setEval_notes_active_disabled] = useState(false);
  const [Eval_notes_active_disabled_mfep, setEval_notes_active_disabled_mfep] = useState(false);

  const [EditingInfo_active_etab_disabled, setEditingInfo_active_etab_disabled] = useState(false);
  const [EditingInfo_active_etab_disabled_mfep, setEditingInfo_active_etab_disabled_mfep] = useState(false);

  const [download_cert_scol_active_etab_disabled, setdownload_cert_scol_active_etab_disabled] = useState(false);
  const [download_cert_scol_active_etab_disabled_mfep, setdownload_cert_scol_active_etab_disabled_mfep] = useState(false);

  const [download_cert_inscription_active_etab_disabled, setdownload_cert_inscription_active_etab_disabled] = useState(false);
  const [download_cert_inscription_active_etab_disabled_mfep, setdownload_cert_inscription_active_etab_disabled_mfep] = useState(false);

  const [IDDFEP_selected, setIDDFEP_selected] = useState(1);

  const [loadingdata_mfep, setloadingdata_mfep] = useState(false);




  const [selectedOption, setSelectedOption] = useState(null);


  const onwlyChanged = (event, values) => {

    if (values) {
      setSelectedOption(values);
      setIDDFEP_selected(values.value);
    } else {
      return;
    }


  }



  const useStyles_listdfep = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
        fontFamily:
          '"Tajawal", sans-serif',
        fontSize: 18,
        direction: "rtl"
      }
    },
    inputRoot: {

      color: "#004d40",


      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        fontFamily: '"Tajawal", sans-serif',
        fontWeight: 700,
        color: "#004d40",
        // Default left padding is 6px
        paddingLeft: 26
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#004d40"
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#00796b"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "2px",
        borderColor: "#004d00"
      }
    }
  }));

  const classes_listdfep = useStyles_listdfep();



  const apiUrldfep = `${API_BASE_URL}/api/getlistofalldfep`;



  // Fetch data from the API when the component mounts
  useEffect(() => {



    setloadingdata_mfep(true);
    fetch(apiUrldfep,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then(response => response.json())
      .then(data => {
        // Assuming 'data' is an array of objects
        const Wlylists = data.data; // Extracting the relevant data

        /////////////Wilayets///////////////////

        const WlyOnly = Wlylists.filter(Wlylist => Wlylist.Nom !== null);

        // Sorting
        options_Wly = WlyOnly.sort((a, b) => Number(a.IDDFEP) - Number(b.IDDFEP))
          .map((Wlylist, i) => ({
            value: Wlylist.IDDFEP,
            label: Wlylist.IDDFEP + '- ' + Wlylist.Nom,
            indexvalue: i
          }));

        options_Wly.unshift({ value: 99, label: 'وزارة التكوين والتعليم المهنيين', indexvalue: -1 });

        setoptions_Wlylist(options_Wly);

        /// console.log('options_Wly[0].value:'+options_Wly[0].value);
        setloadingdata_mfep(false);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });





  }, []);

  useEffect(() => {
    if (options_Wlylist && options_Wlylist.length > 0) {
      setSelectedOption(options_Wlylist[0]);
      setIDDFEP_selected(options_Wlylist[0].value);
    }

  }, [options_Wlylist]);



  // Fetch data from the API when the component mounts
  React.useEffect(() => {
    setloadingdata_mfep(true);


    fetch(apiUrl + '/' + IDDFEP_selected + '/1',
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        }
      })
      .then((response) => response.json())
      .then((data) => {
        const apiRows = data.data.map((item, index) => ({

          id: item.IDetablissement, // Maintain original column name for clarity
          id_rows: index + 1,
          IDetablissement: item.IDetablissement,
          Code_etab: item.Code,
          Nom_etab: item.Nom,
          wilayaNom: item.libelleArabe,
          communn_Nom: item.Nom_Communn, // Use the appropriate column name
          nomUser: item.nomUser, // Maintain original column name for clarity
          IsActive: item.activee,
          MotDePass: '****',///  item.MotDePass,
          MotDePassTassyir:item.MotDePassTassyir,
          download_cert_scol_active: item.download_cert_scol_active,
          download_cert_inscription_active: item.download_cert_inscription_active,
          EditingInfo_active: item.EditingInfo_active,
          Eval_notes_active: item.Eval_notes_active,
          actions: '',
        }));
        setRows(apiRows);
        rowsdata = apiRows;

        setloadingdata_mfep(false);

      })
      .catch((error) => console.error('Error fetching data:', error));





  }, [IDDFEP_selected]);

  function setValuesFromData_getinfo(data, data_dfep, data_mfep) {
    // Check if data object is defined
    if (!data || !data_dfep || !data_mfep) {
      console.error('Data is undefined');
      return;
    }

    setNom_etab(data.Nom);
    setlibelleArabe_etab(data.libelleArabe);
    setSingup_active_etab(data.Singup_active);
    setdownload_cert_scol_active_etab(data.download_cert_scol_active);
    setEditingInfo_active_etab(data.EditingInfo_active);
    setdownload_cert_inscription_active_etab(data.download_cert_inscription_active);



    ////Eval_notes_active
    if (data.Eval_notes_active == '1' || Number(data.Eval_notes_active) == 1 || Number(data.Eval_notes_active) === 1) {
      setEval_notes_active_enable(true);
    } else {
      setEval_notes_active_enable(false);
    }

    if (data_dfep.Eval_notes_active_dfep == '1' || Number(data_dfep.Eval_notes_active_dfep) == 1 || Number(data_dfep.Eval_notes_active_dfep) === 1) {

      setEval_notes_active_disabled(true);
    } else {

      setEval_notes_active_disabled(false);
    }

    if (data_mfep.Eval_notes_active_mfep == '1' || Number(data_mfep.Eval_notes_active_mfep) == 1 || Number(data_mfep.Eval_notes_active_mfep) === 1) {

      setEval_notes_active_disabled_mfep(true);
    } else {

      setEval_notes_active_disabled_mfep(false);
    }

    ////EditingInfo_active
    if (data.EditingInfo_active == '1' || Number(data.EditingInfo_active) == 1 || Number(data.EditingInfo_active) === 1) {
      setEditingInfo_active_etab_enable(true);
    }
    else {
      setEditingInfo_active_etab_enable(false);
    }

    if (data_dfep.EditingInfo_active_dfep == '1' || Number(data_dfep.EditingInfo_active_dfep) == 1 || Number(data_dfep.EditingInfo_active_dfep) === 1) {
      setEditingInfo_active_etab_disabled(true);
    } else {
      setEditingInfo_active_etab_disabled(false);
    }

    if (data_mfep.EditingInfo_active_mfep == '1' || Number(data_mfep.EditingInfo_active_mfep) == 1 || Number(data_mfep.EditingInfo_active_mfep) === 1) {
      setEditingInfo_active_etab_disabled_mfep(true);
    } else {
      setEditingInfo_active_etab_disabled_mfep(false);
    }



    ////download_cert_scol_active
    if (data.download_cert_scol_active == '1' || Number(data.download_cert_scol_active) == 1 || Number(data.download_cert_scol_active) === 1) {
      setdownload_cert_scol_active_etab_enable(true);
    } else {
      setdownload_cert_scol_active_etab_enable(false);
    }

    if (data_dfep.download_cert_scol_active_dfep == '1' || Number(data_dfep.download_cert_scol_active_dfep) == 1 || Number(data_dfep.download_cert_scol_active_dfep) === 1) {
      setdownload_cert_scol_active_etab_disabled(true);
    } else {
      setdownload_cert_scol_active_etab_disabled(false);
    }

    if (data_mfep.download_cert_scol_active_mfep == '1' || Number(data_mfep.download_cert_scol_active_mfep) == 1 || Number(data_mfep.download_cert_scol_active_mfep) === 1) {
      setdownload_cert_scol_active_etab_disabled_mfep(true);
    } else {
      setdownload_cert_scol_active_etab_disabled_mfep(false);
    }



    ////download_cert_inscription_active
    if (data.download_cert_inscription_active == '1' || Number(data.download_cert_inscription_active) == 1 || Number(data.download_cert_inscription_active) === 1) {
      setdownload_cert_inscription_active_etab_enable(true);
    } else {
      setdownload_cert_inscription_active_etab_enable(false);
    }

    if (data_dfep.download_cert_inscription_active_dfep == '1' || Number(data_dfep.download_cert_inscription_active_dfep) == 1 || Number(data_dfep.download_cert_inscription_active_dfep) === 1) {
      setdownload_cert_inscription_active_etab_disabled(true);
    } else {
      setdownload_cert_inscription_active_etab_disabled(false);
    }

    if (data_mfep.download_cert_inscription_active_mfep == '1' || Number(data_mfep.download_cert_inscription_active_mfep) == 1 || Number(data_mfep.download_cert_inscription_active_mfep) === 1) {
      setdownload_cert_inscription_active_etab_disabled_mfep(true);
    } else {
      setdownload_cert_inscription_active_etab_disabled_mfep(false);
    }


  }




  useEffect(() => {
    if ((IDetablissement && singtoggleclicked)) {
      ontoggleupdatedata();
      setsingtoggleclicked(false);
    }

    if ((IDetablissement && downtoggleclicked)) {
      ontoggleupdatedata();
      setdowntoggleclicked(false);
    }

    if ((IDetablissement && down_inscriptiontoggleclicked)) {
      ontoggleupdatedata();
      setdown_inscriptiontoggleclicked(false);
    }

    if ((IDetablissement && edittoggleclicked)) {
      ontoggleupdatedata();
      setedittoggleclicked(false);
    }

    if ((IDetablissement && notestoggleclicked)) {
      ontoggleupdatedata();
      setnotestoggleclicked(false);
    }

  }, [download_cert_scol_active_etab_enable, download_cert_inscription_active_etab_enable, EditingInfo_active_etab_enable, Eval_notes_active_enable]);

  const ontoggleclicked_singup = (event) => {
    setsingtoggleclicked(1);

    if (Number(Singup_active_etab) == 1) {
      setSingup_active_etab(0);
    } else {
      setSingup_active_etab(1);
    }
  };

  const ontoggleclicked_download = (event) => {
    setdowntoggleclicked(true);
    if (download_cert_scol_active_etab_enable) {
      setdownload_cert_scol_active_etab_enable(false);
    } else {
      setdownload_cert_scol_active_etab_enable(true);
    }
  };

  const ontoggleclicked_download_inscription = (event) => {
    setdown_inscriptiontoggleclicked(true);
    if (download_cert_inscription_active_etab_enable) {
      setdownload_cert_inscription_active_etab_enable(false);
    } else {
      setdownload_cert_inscription_active_etab_enable(true);
    }
  };





  const ontoggleclicked_notes = (event) => {
    setnotestoggleclicked(true);
    if (Eval_notes_active_enable) {
      setEval_notes_active_enable(false);
    } else {
      setEval_notes_active_enable(true);
    }
  };

  const ontoggleclicked_edit = (event) => {
    setedittoggleclicked(true);
    if (EditingInfo_active_etab_enable) {
      setEditingInfo_active_etab_enable(false);
    } else {
      setEditingInfo_active_etab_enable(true);
    }
  };

  const ontoggleupdatedata = async (e) => {

    ////alert(download_cert_scol_active_etab_enable ? 0 : 2);
    try {
      const data = {
        Singup_active: Singup_active_etab ? 0 : 2,
        download_cert_scol_active: download_cert_scol_active_etab_enable ? 0 : 2,
        download_cert_inscription_active: download_cert_inscription_active_etab_enable ? 0 : 2,
        EditingInfo_active: EditingInfo_active_etab_enable ? 0 : 2,
        Eval_notes_active: Eval_notes_active_enable ? 0 : 2,
        IDetablissement: sessionStorage.getItem("IDetablissement"),
        IDDFEP: sessionStorage.getItem("IDDFEP"),


      };
      // Convert the data object to a JSON string
      const jsonData = JSON.stringify(data);

      // Alert the JSON string
      ///alert(jsonData);



      const responseFromApi = await axios.post(`${API_BASE_URL}/api/updateSettingDfep_forall`, data,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        });

      if (responseFromApi.status === 200) {
        // Response is OK, show the updated data
        //////console.log("Updated data:", responseFromApi.data.updatedData);
        //////console.log("responseFromApi Eval_notes_active:", responseFromApi.data.updatedData.Eval_notes_active);


        setSnackbar({ children: 'تم الحفظ بنجاح!', severity: 'success' });

        // Do something with the updated data, such as updating the UI
      } else {
        // Log error if response status is not OK
        console.error("Error:", responseFromApi.data.error);
      }
    } catch (error) {
      // Log any other errors, such as network errors
      console.error("Error:", error.message);
    }
  };



  // Update the ref whenever editedValue changes
  useEffect(() => {
    editedValueRef.current = editedValue;
  }, [editedValue]);



  const renderEditCellWithFontSize = (params, fontSize) => {
    return (
      <TextField
        value={params.value}
        onChange={(e) => params.api.setValue(params.id, e.target.value)}
        style={{ fontSize: fontSize }} // Apply the specified font size here
      />
    );
  };


  // Define state variable to store selected IDs for each Select component
  const [selectedIDs, setSelectedIDs] = useState({});


  // Handler to update selected ID for a specific Select component
  const handleChange_privilege_users = (event, index, id) => {
    const newID = event.target.value;

    // Update selectedIDs state
    setSelectedIDs(prevState => ({
      ...prevState,
      [id]: newID,
    }));



    /* // Update the corresponding row in the rows state with the new ID
   setRows(prevRows => {
     const updatedRows = prevRows.map(row => {
       if (row.id === id) {
         return { ...row, IDprivilege_users: newID };
       }
       return row;
     });
     
     // Alert the updatedRow here, inside the setRows callback
     alert(`Row Data After Update: ${JSON.stringify(updatedRows.find(row => row.id === id))}`);
     
     return updatedRows;
   });*/




  };


  // Render EditCell for privilege_users
  const renderEditCell_privilege_users = (params) => {
    /// //console.log("selectedIDs[params.value]",selectedIDs[params.value]);
    /////console.log("selectedIDs[params.value]",params.id);
    return (
      <div>
        <FormControl fullWidth>
          <Select
            label={`Select NomAr ${params.id}`}
            sx={{
              fontFamily: 'Amiri',
              fontSize: '18px',
              fontWeight: 'bold',
            }}
            value={selectedIDs[params.id] || params.value} // Get value from selectedIDs based on params.value
            onChange={(event) => handleChange_privilege_users(event, params.value, params.id)} // Pass params.value to handleChange_privilege_users
          >
            {privilege_users_data.map(item => (
              <MenuItem
                sx={{
                  fontFamily: 'Amiri',
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}

                key={item.ID} value={item.ID}>{item.NomAr}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  // Render Cell for privilege_users
  const renderCell_privilege_users = (params) => {
    const rowData = privilege_users_data.find(item => Number(item.ID) === Number(params.value));
    return (
      <div>
        {rowData ? rowData.NomAr : ''}
      </div>
    );
  };



  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut || params.reason === GridRowEditStopReasons.commit) {
      event.defaultMuiPrevented = true;
      const editedRow = rows.find((row) => row.id === params.id);

      if (editedRow) {
        /// alert(`Editing stopped for ID: ${editedRow.id}`);
        // Trigger the save operation for the edited row
        ////handleSaveOperation(editedRow.id);


      }
    }
  };




  const handleEditClick = (id) => () => {
    const rowToEdit = rows.find((row) => row.id === id);
    const editedValues = {};

    // Copy all fields from the row to the editedValues object
    Object.keys(rowToEdit).forEach((field) => {
      editedValues[field] = rowToEdit[field];
    });

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setEditedValue({ ...editedValue, [id]: editedValues });
  };



  const [idsaved, setidsaved] = useState(0);


  const [confirmationTrigger, setConfirmationTrigger] = useState(false);



  useEffect(() => {
    if (confirmationTrigger) {
      setSaveConfirmationOpen(true);
      setConfirmationTrigger(false); // Reset the trigger after executing
    }
  }, [confirmationTrigger, rowModesModel]);



  const handleDeleteClick = (id) => () => {
    return;
    const rowToDelete = rows.find((row) => row.id === id);
    if (rowToDelete) {
      // Extract NomAr value from the row
      const { id } = rowToDelete;
      const { NomEtPrenom } = rowToDelete;
      const { NomAr } = rowToDelete;
      // Open the confirmation dialog
      setConfirmationOpen(true);
      setDeletedRowId(id);
      setDeleted_NomAr(NomEtPrenom + ' من ' + NomAr);


    }


  };




  const handleConfirmationResponse = async (confirmed) => {

    // Close the confirmation dialog
    setConfirmationOpen(false);
    if (confirmed) {
      const idToDelete = deletedRowId;
      setDeletedRowId(null); // Reset deletedRowId to avoid multiple deletions

      try {
        // Send the API request to delete the row from the Laravel backend
        const response = await fetch(`${API_BASE_URL}/api/deleteRow_privilege_users_details/${idToDelete}`, {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
          }
        });

        if (response.ok) {
          // If the API request is successful, update the local state to remove the row
          setRows((prevRows) => prevRows.filter((row) => row.id !== idToDelete));
          ////////console.log('Row deleted successfully from Laravel backend.');
          setSnackbar({ children: '  تم الحذف بنجاح  ', severity: 'success' });

        } else {
          console.error('Failed to delete row from Laravel backend.');
          // Handle the error or display a notification to the user
        }
      } catch (error) {
        console.error('Error during the API request:', error);
        // Handle the error or display a notification to the user
      }
    } else {
      // User clicked "Cancel," do nothing
    }
  };

  const handleSaveConfirmationResponse = async (confirmed) => {
    // Close the confirmation dialog
    setSaveConfirmationOpen(false);

    if (confirmed) {
      const idforsavea = idforsave;
      handleSaveOperation(idforsavea);
      setidforsave(null);
    } else {
      // User clicked "Cancel," do nothing
    }
  };

  const processRowUpdate_isactive = async ({ id, IsActive }) => {
    // Update the row with the new isActive value
    const updatedRows = rows.map(row =>
      row.id === id ? { ...row, IsActive } : row
    );
    setRows(updatedRows);
  };


  const processRowUpdate = async (newRow) => {
    // Check if the row is new
    const isNewRow = rows.find((row) => row.id === newRow.id)?.isNew;

    // Preserve the original isNew value if it exists
    const updatedRow = { ...newRow, isNew: isNewRow };

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    // Display an alert with the updated row data and id
    ///alert(`Row Data After Update for ID ${newRow.id}: ${JSON.stringify(updatedRow)}`);

    try {
      // Call handleSaveOperation with the id
      await handleSaveOperation(newRow.id, updatedRow);
    } catch (error) {
      console.error('Error during handleSaveOperation:', error);
    }

    return updatedRow;
  };





  const handleSaveOperation = async (id, updatedRow) => {
    // Check if updatedRow is defined and has the IDprivilege_users property

    //////const newID = selectedIDs[id] || updatedRow.IDprivilege_users;
    ///alert(newID); // Alert the new ID


    const editedRow = updatedRow;
    if (!editedRow) {
      console.error('Row not found for ID:', id);
      return;
    }

    ///alert(`Row Data After Update: ${JSON.stringify(editedRow)}`);
    /////console.log(`Row Data After Update: ${JSON.stringify(editedRow)}`);
    try {
      let response;


      // If the row is not new, it means it's already in the database, so update it
      response = await fetch(`${API_BASE_URL}/api/update_etablissement`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json', // Specify the content type
          Authorization: `Bearer ${sessionStorage.getItem("authData_73xJ#E")}`
        },
        body: JSON.stringify(editedRow),
      });


      if (response.ok) {

        // Update the datagrid with the edited row
        setRows(prevRows => {
          return prevRows.map(row => {
            if (row.id === id) {
              // If the row being updated has the same id as the edited row
              // Update all fields except for the MotDePass
              return { ...editedRow, isNew: false, MotDePass: '****' };
            }
            return row;
          });
        });


        // Set the snackbar message
        setSnackbar({ children: '  تم الحفظ  ', severity: 'success' });

        // Set the row mode to view after saving
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
      } else {
        console.error('Failed to save record in the database.');

        // Extract validation error messages from the API response
        const errorData = await response.json();
        const validationErrors = errorData?.error;
        console.error('validationErrors:', validationErrors);
        console.error('validationmessage:', errorData.message);

        // Concatenate validation error messages
        const errorMessage =
          'الأخطاء:' +
          Object.values(validationErrors || {}).join(', ');

        console.error('errorMessage:', errorMessage);

        setSnackbar({ children: validationErrors, severity: 'error' });



        // Set the snackbar message for the error
        ///setSnackbar({ children: errorMessage, severity: 'error' });
        ///setSnackbar({ children: errorMessage.replace(/\\n/g, '\n'), severity: 'error' });

      }
    } catch (error) {
      console.error('Error during the API request:', error);

      // Set the snackbar message for the error
      setSnackbar({ children: 'حدث خطأ', severity: 'error' });
    }



  };




  const SaveConfirmationDialog = ({ open, onClose }) => {
    return (
      <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>Confirm Save</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to save this row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onClose(true)} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };





  const [addRecordClicked, setAddRecordClicked] = useState(false);

  useEffect(() => {
    if (addRecordClicked) {
      handleAddRecord();
      setAddRecordClicked(false);
    }
  }, [addRecordClicked]);

  const handleAddClick = () => {
    setIsAdding(true);
    setAddRecordClicked(true);
  };




  const handleAddRecord = () => {
    return;
    const id = uuidv4();

    // Default values
    let defaultIDprivilege_users = '';

    // If rows have been fetched and there is at least one row
    if (rows.length > 0) {
      // Use values from the last row
      const lastRow = rows[rows.length - 1];
      if (lastRow) {
        defaultIDprivilege_users = lastRow.IDprivilege_users || '';
      }
    }



    // Add an empty row at the end of the existing rows
    const emptyRow = {
      id: id,
      idprivilege_users_details: 'addnew',
      IDetablissement: IDetablissement,
      IDprivilege_users: defaultIDprivilege_users,
      NomAr: '',
      Endroit: '',
      Service_abr: '',
      NomEtPrenom: '',
      nomUser: '',
      IsActive: 0,
      MotDePass: '',
      actions: '',
      isNew: true,
    };

    setRows((oldRows) => [...oldRows, emptyRow]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'NomEtPrenom' },
    }));
  };




  const handleRowModesModelChange = (newRowModesModel) => {

    setRowModesModel(newRowModesModel);
  };



  /*

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  
    // Check if a row is in edit mode
    const editedRowId = Object.keys(newRowModesModel).find(
      (id) => newRowModesModel[id]?.mode === GridRowModes.Edit
    );
  
    // If a row is in edit mode, trigger the save operation
    if (editedRowId) {
      handleSaveOperation(editedRowId);
    }
  };

*/
  const preProcessEditCellPropsAr = async (params) => {
    const errorMessage = await validateNamear(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };

  const preProcessEditCellProps = async (params) => {
    const errorMessage = await validateName(params.props.value.toString());
    return { ...params.props, error: errorMessage };
  };


  const useStyles = makeStyles({
    cancelBtn: {
      backgroundColor: "#004d40", // أخضر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#388e3c",
      },
    },
    yesBtn: {
      backgroundColor: "#f44336", // أحمر
      color: "#fff",
      "&:hover": {
        backgroundColor: "#d32f2f",
      },
    },
  });

  const ConfirmationDialog = ({ open, onClose }) => {
    const classes = useStyles();

    return (
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={themetextfieldnew}>


          <Dialog
            open={open} onClose={() => onClose(false)}>
            <DialogTitle>
              <Divider >
                <Grid item sx={{ mt: 2 }} >
                  <Typography
                    style={{
                      color: "red",
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    تأكيد الحذف <DeleteIcon sx={{ ml: '0px' }} />
                  </Typography>
                </Grid>
              </Divider>


            </DialogTitle>



            <DialogContent>
              <DialogContentText
                sx={{ fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900 }}>
                هل أنت متأكد من أنك تريد حذف <span style={{ color: 'black' }}>{deleted_NomAr}؟</span>

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onClose(false)}
                className={classes.cancelBtn}
                color="primary"
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#004d40", // أخضر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#388e3c",
                  },
                }}
              >
                إلغاء
              </Button>
              <Button
                onClick={() => onClose(true)}
                className={classes.yesBtn}
                color="primary"
                autoFocus
                sx={{
                  fontFamily: '"Tajawal", sans-serif', fontSize: 16, fontWeight: 900,
                  backgroundColor: "#f44336", // أحمر
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
              >
                نعم
              </Button>
            </DialogActions>
          </Dialog>

        </ThemeProvider>
      </CacheProvider>
    );
  };


  const handleExportExcel = () => {
    // Extracting only the 'name' field and 'headerName' from columns
    const relevantColumns = columns.map(col => ({ name: col.field, headerName: col.headerName }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows.map(row => {
      const newRow = {};
      relevantColumns.forEach(col => {
        newRow[col.headerName] = row[col.name];
      });
      return newRow;
    }));

    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: 'Calibri',
        sz: 11,
        bold: true,
        color: {
          rgb: 'FFFFAA00',
        },
      },
    };

    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    const timestamp = Math.floor(Date.now() / 1000);

    XLSX.writeFile(wb, `Moutakawin_${timestamp}.xlsx`);
  };

  /*
  
  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows, {
      header: columns.map((col) => col.headerName),
    });
  
    // Add cell styling as needed
    // Example:
    ws["A1"].s = {
      font: {
        name: 'Calibri',
        sz: 11,
        bold: true,
        color: {
          rgb: 'FFFFAA00',
        },
      },
    };
  
    XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
    XLSX.writeFile(wb, 'exported_data.xlsx');
  };*/

  const handleSaveClick = (id, params) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });


  };

  const [showPassword, setShowPassword] = React.useState(false);

  const [visibleRowId, setVisibleRowId] = React.useState(null);

  const togglePasswordVisibility = (id) => {
    setVisibleRowId((prevId) => (prevId === id ? null : id));
  };

  const EditCellRenderer = ({ params, onUpdate }) => {
    const { id, value, api, field } = params;
    const [isActive, setIsActive] = useState(value);

    const handleChange = () => {
      const newValue = !isActive;
      setIsActive(newValue);
      const value_activee = isActive ? "0" : "1";
      // Call the onUpdate function with the new value
      onUpdate({ id, IsActive: value_activee });

    };

    return (
      <Checkbox
        checked={!isActive}
        onChange={handleChange}
        color="success"
        sx={{
          '&.Mui-checked': {
            color: '#00695c', // Custom green color
          },
        }}
      />
    );
  };


  const columns = [
    {
      field: 'id_rows',
      headerName: 'ترقيم',
      minWidth: 70,
      headerClassName: 'super-app-theme--header',
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'Code_etab',
      headerClassName: 'super-app-theme--header',
      headerName: 'رمز المؤسسة',
      editable: false,
      minWidth: 170,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'Nom_etab',
      headerClassName: 'super-app-theme--header',
      headerName: 'المؤسسة',
      editable: false, // Adjust based on your requirements
      flex: 1, // Allow column to grow/shrink
      minWidth: 400, // Set a minimum width
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'wilayaNom',
      headerClassName: 'super-app-theme--header',
      headerName: 'الولاية',
      editable: false, // Adjust based on your requirements
      minWidth: 200, // Set a minimum width
      align: 'center',
      headerAlign: 'center',
    }
    ,
    {
      field: 'communn_Nom',
      headerClassName: 'super-app-theme--header',
      headerName: 'البلدية',
      editable: false, // Adjust based on your requirements
      minWidth: 200, // Set a minimum width
      align: 'center',
      headerAlign: 'center',
    }

    ,
    {
      field: 'nomUser',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'cell-editable', // Add a custom header class for this column
      headerName: 'إسم المستخدم',

      editable: true, // Adjust based on your requirements
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'MotDePass',
      headerName: 'كلمة السر',
      editable: true,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'cell-editable', // Add a custom header class for this column
      headerAlign: 'center',
      minWidth: 200,
      align: 'center',
    }
    ,
    {
      field: 'MotDePassTassyir',
      headerName: 'كلمة سر تسيير',
      editable: false,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'cell-editable', // Add a custom header class for this column
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ marginLeft: 10 }} >
            {visibleRowId === params.id ? params.value : '******'}
          </span>
          <IconButton onClick={() => togglePasswordVisibility(params.id)} size="small">
            {visibleRowId === params.id ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </div>
      ),
    },

    /*
    ,{
      field: 'EditingInfo_active',
      headerName: 'تعديل معلومات المتكون',
      editable: true,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'cell-editable', // Add a custom header class for this column
      headerAlign: 'center',
      minWidth: 250,
      align: 'center',
      
      renderCell: (params) => (
        <div>
        <Checkbox disabled  checked={params.value !== 2} />
        {params.value !== 2 ? 'مسموح' : 'غير مسموح'}
           </div>   
      ),
      renderEditCell: (params) => {
        return (
          <div>
            <Checkbox
              checked={params.value !== 2}  // Set checked based on params.value
              onChange={(event) => {
                //console.log("Checkbox clicked");
                const newValue = event.target.checked ? 0 : 2; // Set newValue based on checked state
                //console.log("New Value:", newValue);
                //console.log("Params:", params);
      
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: newValue,
                });
              }}
            />
            {params.value !== 2 ? 'مسموح' : 'غير مسموح'}
          </div>
        );
      },

    }*/

    , {
      field: 'download_cert_inscription_active',
      headerName: 'تحميل شهادة تسجيل',
      editable: true,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'cell-editable', // Add a custom header class for this column
      headerAlign: 'center',
      minWidth: 250,
      align: 'center',

      renderCell: (params) => (
        <div>
          <Checkbox disabled checked={params.value !== 2} 
            
          />
          {params.value !== 2 ? 'مسموح' : 'غير مسموح'}
        </div>
      ),
      renderEditCell: (params) => {
        return (
          <div>
            <Checkbox
              checked={params.value !== 2}  // Set checked based on params.value
              onChange={(event) => {
                //console.log("Checkbox clicked");
                const newValue = event.target.checked ? 0 : 2; // Set newValue based on checked state
                //console.log("New Value:", newValue);
                //console.log("Params:", params);

                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: newValue,
                });
              }}
              sx={{
                '&.Mui-checked': {
                  color: '#00695c', // Custom green color
                },
              }}
            />
            {params.value !== 2 ? 'مسموح' : 'غير مسموح'}
          </div>
        );
      },

    }
    , {
      field: 'download_cert_scol_active',
      headerName: 'تحميل شهادة تربص',
      editable: true,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'cell-editable', // Add a custom header class for this column
      headerAlign: 'center',
      minWidth: 250,
      align: 'center',

      renderCell: (params) => (
        <div>
          <Checkbox disabled checked={params.value !== 2} 
           
          />
          {params.value !== 2 ? 'مسموح' : 'غير مسموح'}
        </div>
      ),
      renderEditCell: (params) => {
        return (
          <div>
            <Checkbox
              checked={params.value !== 2}  // Set checked based on params.value
              onChange={(event) => {
                //console.log("Checkbox clicked");
                const newValue = event.target.checked ? 0 : 2; // Set newValue based on checked state
                //console.log("New Value:", newValue);
                //console.log("Params:", params);

                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: newValue,
                });
              }}
              sx={{
                '&.Mui-checked': {
                  color: '#00695c', // Custom green color
                },
              }}
            />
            {params.value !== 2 ? 'مسموح' : 'غير مسموح'}
          </div>
        );
      },

    }
    , {
      field: 'Eval_notes_active',
      headerName: 'معاينة نتائج السداسي الحالي',
      editable: true,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'cell-editable', // Add a custom header class for this column
      headerAlign: 'center',
      minWidth: 250,
      align: 'center',

      renderCell: (params) => (
        <div>
          <Checkbox disabled checked={params.value !== 2} 
           
          />
          {params.value !== 2 ? 'مسموح' : 'غير مسموح'}
        </div>
      ),
      renderEditCell: (params) => {
        return (
          <div>
            <Checkbox
              checked={params.value !== 2}  // Set checked based on params.value
              onChange={(event) => {
                //console.log("Checkbox clicked");
                const newValue = event.target.checked ? 0 : 2; // Set newValue based on checked state
                //console.log("New Value:", newValue);
                //console.log("Params:", params);

                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: newValue,
                });
              }}

              sx={{
                '&.Mui-checked': {
                  color: '#00695c', // Custom green color
                },
              }}
            />
            {params.value !== 2 ? 'مسموح' : 'غير مسموح'}
          </div>
        );
      },

    }
    , {
      field: 'IsActive',
      headerName: 'حالة الحساب',
      editable: true,
      headerClassName: 'super-app-theme--header',
      cellClassName: 'cell-editable', // Add a custom header class for this column
      headerAlign: 'center',
      minWidth: 250,
      align: 'center',

      renderCell: (params) => (
        <div>
          <Checkbox disabled checked={Number(params.value) === 0} 
          
          />
          {Number(params.value) === 0 ? 'قَيْد التشغيل' : 'مُعَطَّل'}
        </div>
      ),
      renderEditCell: (params) => {
        return (
          <div>
            <Checkbox
              checked={Number(params.value) === 0}  // Set checked based on params.value

              onChange={(event) => {
                //console.log("Checkbox clicked");
                const newValue = event.target.checked ? 0 : 1;
                //console.log("New Value:", newValue);
                //console.log("Params:", params);

                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: newValue,
                });

              }}
              sx={{
                '&.Mui-checked': {
                  color: '#00695c', // Custom green color
                },
              }}
            />
            {Number(params.value) === 0 ? 'قَيْد التشغيل' : 'مُعَطَّل'}
          </div>
        );
      },



    },


    ///{ field: 'crypted_id', headerName: 'crypted_id', width: 200, editable: false },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'إجراءات',
      flex: 1,
      align: 'left',
      minWidth: 220,
      cellClassName: 'actions',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      getActions: ({ id, params }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <Button
            sx={{
              backgroundColor: '#00695c', // Green color
              '&:hover': {
                backgroundColor: '#004d40', // Darker green on hover
              }
            }}
              onClick={handleSaveClick(id, params)}
              variant="contained" 
              endIcon={<SaveIcon sx={{ mr: '2px', ml: '-2px', transform: 'rotate(0deg)' }} />} style={{  width: 'fit-content' }}>
              <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
                حفظ
              </Typography>
            </Button>

            ,
            <Button
              onClick={handleCancelClick(id)}
              variant="contained" endIcon={<CancelIcon sx={{ mr: '2px', ml: '-2px', transform: 'rotate(0deg)' }} />} style={{ backgroundColor: 'grey', width: 'fit-content' }}>
              <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
                إلغاء
              </Typography>
            </Button>
            ,
          ];
        }

        return [

          

          <Button
          sx={{
            backgroundColor: '#00695c', // Green color
            '&:hover': {
              backgroundColor: '#004d40', // Darker green on hover
            }
          }}
            onClick={handleEditClick(id)}
            variant="contained" endIcon={<EditIcon sx={{ mr: '2px', ml: '-2px', transform: 'rotate(0deg)' }} />} style={{   width: 'fit-content' }}>
            <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
              تعديل
            </Typography>
          </Button>

          /* <GridActionsCellItem
            icon={<EditIcon style={{ color: 'green' }} />}
            label="Edit"
            className="textPrimary"
            
            color="inherit"
          />*/

          /*
       ,
       <Button 
       onClick={handleDeleteClick(id)}
       variant="contained" endIcon={<DeleteIcon sx={{mr:'0px', ml:'0px', transform: 'rotate(0deg)'}} />} style={{backgroundColor: 'red', width: 'fit-content'}}>
        <Typography sx={{ color: "#FFFFFF", fontFamily: '"Tajawal", sans-serif', fontSize: 12, fontWeight: 900 }}>
          حذف
        </Typography>
      </Button>
     
        <GridActionsCellItem
          icon={<DeleteIcon style={{ color: 'red' }} />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
 
         /> */



          ,
        ];
      },
    },
  ];


  const styles_cell_editable = {
    cell_editable: {
      border: '2px solid green', // Define the border style
    },
  };



  const CellRenderer = ({ params }) => {
    const { id, value } = params;
    const [isActive, setIsActive] = useState(value);

    const handleChange = () => {
      setIsActive(!isActive);
    };

    return (

      <div>
        <Checkbox
          checked={!isActive}
          onChange={handleChange}
          disabled={!params.row.editable}
          color="success" // Set the color to "success"
          sx={{
            '&.Mui-checked': {
              color: '#00695c', // Custom green color
            },
          }}
           
        />
        {!isActive ? 'قَيْد التشغيل' : 'مُعَطَّل'}
      </div>
    );
  };


  /*
    const EditCellRenderer = ({ params, onUpdate  }) => {
      const { id, value, api, field } = params;
      const [isActive, setIsActive] = useState(value);
    
      const handleChange = () => {
        const newValue = !isActive;
      setIsActive(newValue);
      onUpdate(newValue);
      };
    
      return (
       
  
        <Checkbox
        checked={!isActive}
        onChange={handleChange}
         color="success" // Set the color to "success"
      />
  
      );
    };
  
    */




  const CellRenderer_openclose = ({ params }) => { /// dfep 0 or 2
    const { id, value } = params;
    const [isActive, setIsActive] = useState(value);

    const handleChange = () => {
      if (Number(isActive) === 1 || Number(isActive) === 0) {
        setIsActive(2);
      } else {
        setIsActive(0);
      }

    };

    return (

      <div>
        <Checkbox
          checked={Number(isActive) === 1 || Number(isActive) === 0 ? true : false}
          onChange={handleChange}
          disabled={!params.row.editable}
          color="success" // Set the color to "success"
          sx={{
            '&.Mui-checked': {
              color: '#00695c', // Custom green color
            },
          }}
        />
        {isActive ? 'مُتاح' : 'ممنوع'}
      </div>
    );
  };


  const EditCellRenderer_openclose = ({ params }) => {
    const { id, value, api, field } = params;
    const [isActive, setIsActive] = useState(value);

    const handleChange = () => {
      if (Number(isActive) === 1 || Number(isActive) === 0) {
        setIsActive(2);
      } else {
        setIsActive(0);
      }

      //// api.setEditCellValue({ id, field, value: (isActive === 1 || isActive === 0) ? 2 : 0 });
    };

    return (


      <Checkbox
        checked={Number(isActive) === 1 || Number(isActive) === 0 ? true : false}
        onChange={handleChange}
        color="success" // Set the color to "success"
        sx={{
          '&.Mui-checked': {
            color: '#00695c', // Custom green color
          },
        }}
      />

    );
  };


  function generateRandomString(length = 8) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function generateRandomString_nomUser(length = 8) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }




  const CopyToClipboardButton = (value) => {
    // Create a temporary input element
    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    setSnackbar({ children: `  تم نسخ  ${value}  `, severity: 'success' });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };

  const CopyToClipboardButton_nomUser = (value) => {
    // Create a temporary input element
    const tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);

    // Select the value in the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected value
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    setSnackbar({ children: `  تم نسخ  ${value}  `, severity: 'success' });

    // Optionally provide user feedback
    ///alert(`Copied "${value}" to clipboard!`);
  };




  const customTextField_genNomUser = (

    <>

      {/* gen nomUser */}
      <Grid item xs={12} sx={{ mt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>


        <Box  >
          <div dir="rtl">


            <FormControl fullWidth variant="outlined"


            >

              <TextField

                sx={{
                  // Set the width to your desired size

                  //direction:'ltr',

                  '& .MuiFormHelperText-root': {
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: '14px',
                    color: '#004d40',
                    fontWeight: 700

                  },
                  '& .MuiFormLabel-root': {
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: '18px',
                    color: '#004d40',
                    dir: 'rtl',

                  },
                  '& .MuiInputBase-input': {
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: '22px',
                    color: '#004d40',
                    fontWeight: 700,
                    //text-align: right,



                  },
                  '& label.Mui-focused': {
                    fontSize: '16px',
                    color: '#004d40',
                    fontWeight: 700
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#004d40',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: '16px',
                      borderColor: '#004d40',
                      borderWidth: "2px",

                    },
                    '&:hover fieldset': {
                      borderColor: "#00796b",
                    },
                    '&.Mui-focused fieldset': {

                      borderColor: "#00796b",
                    },
                  }
                }}


                label="مولد اسم المستخدم  "



                variant="outlined"
                size={'small'}
                name="valuegenpw_nomUser"
                value={valuegenpw_nomUser}
                InputLabelProps={{
                  shrink: true,
                }}

                placeholder='توليد اسم مستخدم جديد '



                InputProps={{
                  inputProps: { tabIndex: 5 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKeyIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(e) => {
                          const randomValue_nomUser = generateRandomString_nomUser();
                          setvaluegenpw_nomUser(randomValue_nomUser);
                          ////console.log(randomValue);
                        }}
                      >
                        <ReplayIcon sx={{ color: "#004d40" }} />
                      </IconButton>

                      <IconButton
                        onClick={(e) => {
                          CopyToClipboardButton_nomUser(valuegenpw_nomUser);
                        }}
                      >
                        <ContentCopyIcon sx={{ color: "#004d40" }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}



              />

            </FormControl>


          </div>
        </Box>


      </Grid>

    </>

  );


  const customTextField_genpw = (

    <>

      {/* gen MotDePass */}
      <Grid item xs={12} sx={{ mt: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>


        <Box>
          <div dir="rtl">


            <FormControl fullWidth variant="outlined"


            >

              <TextField

                sx={{
                  // Set the width to your desired size

                  //direction:'ltr',

                  '& .MuiFormHelperText-root': {
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: '14px',
                    color: '#004d40',
                    fontWeight: 700

                  },
                  '& .MuiFormLabel-root': {
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: '18px',
                    color: '#004d40',
                    dir: 'rtl',

                  },
                  '& .MuiInputBase-input': {
                    fontFamily: '"Tajawal", sans-serif',
                    fontSize: '22px',
                    color: '#004d40',
                    fontWeight: 700,
                    //text-align: right,



                  },
                  '& label.Mui-focused': {
                    fontSize: '16px',
                    color: '#004d40',
                    fontWeight: 700
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#004d40',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      fontFamily: '"Tajawal", sans-serif',
                      fontSize: '16px',
                      borderColor: '#004d40',
                      borderWidth: "2px",

                    },
                    '&:hover fieldset': {
                      borderColor: "#00796b",
                    },
                    '&.Mui-focused fieldset': {

                      borderColor: "#00796b",
                    },
                  }
                }}


                label="مولد كلمة السر"



                variant="outlined"
                size={'small'}
                name="valuegenpw"
                value={valuegenpw}
                InputLabelProps={{
                  shrink: true,
                }}

                placeholder='توليد كلمة سر'



                InputProps={{
                  inputProps: { tabIndex: 5 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKeyIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(e) => {
                          const randomValue = generateRandomString();
                          setvaluegenpw(randomValue);
                          ////console.log(randomValue);
                        }}
                      >
                        <ReplayIcon sx={{ color: "#004d40" }} />
                      </IconButton>

                      <IconButton
                        onClick={(e) => {
                          CopyToClipboardButton(valuegenpw);
                        }}
                      >
                        <ContentCopyIcon sx={{ color: "#004d40" }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}



              />

            </FormControl>


          </div>
        </Box>

      </Grid>


    </>
  );


  useEffect(() => {
    if (!loadingauth) {
      if (
        (IDUtilisateur === null || !Number(IDUtilisateur) === 999)
      ) {
        // Navigate to the 404 page if any value is missing
        navigate('/');
        return;
      }




    }
  }, [
    IDUtilisateur,
    IDetablissement,
    IDDFEP,
    IDNature_etsF,
    IDCandidat,
    NumSem,
    IDapis_userslist,
    loadingauth,
    navigate,

    Singup_active,
    download_cert_scol_active,
    EditingInfo_active,
    Eval_notes_active,
    download_cert_inscription_active,
    Singup_active_Dfep,
    download_cert_scol_active_Dfep,
    EditingInfo_active_Dfep,
    Eval_notes_active_Dfep,
    download_cert_inscription_active_Dfep,
    Singup_active_Mfep,
    download_cert_scol_active_Mfep,
    EditingInfo_active_Mfep,
    Eval_notes_active_Mfep,
    download_cert_inscription_active_Mfep,
  ]); // Add navigate to dependencies


  if (loadingauth) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress color="success" />
      </Box>
    );
  }



  return (
    <>


      <Header

      />


      <div
        style={{
          display: 'none', // This will make the div and everything inside it not displayed
        }}
      >

        <Box sx={{ mt: 1, mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {loading && <CircularProgress />}
        </Box>

        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme_datagrid}>

            <Container sx={{ textAlign: 'center', mx: 'auto', width: '80%' }}>



              <Card

              >
                <CardContent>
                  <Divider sx={{ mt: '0px' }}>
                    <Grid item  >
                      <Typography
                        style={{
                          color: "#004d40",
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 18,
                          fontWeight: 700,
                          marginBottom: '25px'
                        }}
                        variant="h3" gutterBottom>
                        إعدادات (كل المؤسسات) <SettingsIcon sx={{ ml: '0px' }} />
                      </Typography>
                    </Grid>

                  </Divider>


                  <Grid container alignItems="center">
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        style={{
                          display: 'flex',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          fontFamily: '"Tajawal", sans-serif',
                          textAlign: 'right', // Align text to right by default
                        }}
                      >
                        <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>
                          ولاية: &nbsp;
                        </span> {libelleArabe_etab} &nbsp;
                      </Typography>

                      <Typography
                        variant="body1"
                        style={{
                          display: 'flex',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          fontFamily: '"Tajawal", sans-serif',
                          direction: 'rtl', // Set direction to right-to-left
                          textAlign: 'right', // Align text to right
                        }}
                      >
                        <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>
                          المؤسسة:
                        </span>&nbsp;{Nom_etab}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          display: 'flex',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          fontFamily: '"Tajawal", sans-serif',
                          direction: 'rtl', // Set direction to right-to-left
                          textAlign: 'right', // Align text to right
                        }}
                      >
                        <span style={{ fontWeight: 'bold', color: '#004d40', fontFamily: '"Tajawal", sans-serif', }}>
                          الصلاحيات الممنوحة:
                        </span>&nbsp; كل الصلاحيات
                      </Typography>
                    </Grid>
                  </Grid>

                  <CacheProvider value={cacheRtl}>



                    <p>
                      <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} checked={download_cert_inscription_active_etab_enable} />}
                        label={<span style={{
                          color: "#004d40",
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 900,
                        }}> السماح بتحميل شهادة التسجيل </span>}
                        onClick={ontoggleclicked_download_inscription}
                      />
                    </p>

                    <p>
                      <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} checked={download_cert_scol_active_etab_enable} />}
                        label={<span style={{
                          color: "#004d40",
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 900,
                        }}> السماح بتحميل شهادة التربص </span>}
                        onClick={ontoggleclicked_download}
                      />
                    </p>

                    <p>
                      <FormControlLabel
                        control={<IOSSwitch disabled={!Eval_notes_active_disabled_mfep} sx={{ m: 1 }} checked={Eval_notes_active_enable} />}
                        label={<span style={{
                          color: "#004d40",
                          fontFamily: '"Tajawal", sans-serif',
                          fontSize: 16,
                          fontWeight: 900,
                        }}>

                          {!Eval_notes_active_disabled_mfep ? <>
                            السماح بمعاينة تقييم نتائج السداسي الحالي (مغلوق من الوزارة)
                          </>
                            : <>
                              السماح بمعاينة تقييم نتائج السداسي الحالي
                            </>}

                        </span>}
                        onClick={(!Eval_notes_active_disabled_mfep) ? () => { } : ontoggleclicked_notes}

                      />
                    </p>



                  </CacheProvider>
                </CardContent>

              </Card>




            </Container>

          </ThemeProvider>
        </CacheProvider>

      </div>


      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
          sx={{ marginTop: '50px' }}
        >
          <Alert
            {...snackbar}
            onClose={handleCloseSnackbar}
            sx={{
              display: 'flex', fontFamily: 'Amiri', fontSize: '20px', '& .MuiAlert-icon': { marginLeft: '10px' }
              , '& .MuiAlert-action': { marginRight: '8px' }
            }} // Adjust padding here
          />
        </Snackbar>

      )}

      <Box
        sx={{
          '& .super-app-theme--header': {
            fontFamily: 'Cairo',
            fontSize: '20px',
            fontWeight: 'bold', // Make the font bold
            backgroundColor: '#004d40', // Light green background color

            borderRight: '1px solid #a5d6a7', // Greenish border for better contrast
            fontWeight: 900,
            color: '#FFFFFF'
          },
        }}

      >

        <CacheProvider value={cacheRtl}>



          <Divider sx={{ mt: '20px' }}>
            <Grid item  >
              <Typography
                style={{
                  color: "#004d40",
                  fontFamily: '"Tajawal", sans-serif',
                  fontSize: 25,
                  fontWeight: 700,
                  marginBottom: '25px'
                }}
                variant="h3" gutterBottom>
                <ApartmentIcon sx={{ mr: '2px', fontSize: 40, }} />

                إدارة حسابات المؤسسات

              </Typography>
            </Grid>

          </Divider>


        </CacheProvider>

      </Box>





      <Box
       sx={{
        '& .super-app-theme--header': {
          fontFamily: 'Cairo',
          fontSize: '20px',
          fontWeight: 'bold', // Make the font bold
          backgroundColor: '#004d40', // Light green background color

          borderRight: '1px solid #a5d6a7', // Greenish border for better contrast
          fontWeight: 900,
          color: '#FFFFFF'
        },
      }}

      >


        <Grid container alignItems="center" align="center" justifyContent="center" sx={{ width: "100%", marginBottom: "10px" }}>

          <Grid item xs={12}>
            <Grid item xs={5}>
              {/* Autocomplement Wilayat  */}

              <CacheProvider value={cacheRtl}>

              <Autocomplete
                          noOptionsText={'حدث الصفحة مرة أخرى'}
                          classes={classes_listdfep}
                          id="combo-box-demowly"
                          onChange={onwlyChanged}
                          value={selectedOption} // Use the value prop to set the selected option
                          options={options_Wlylist}
        
                          defaultValue={options_Wlylist[0]} // Set the defaultValue to the first option
                                renderInput={(params) => (
                            <TextField
                              {...params}
                              label="المديريات"


                              sx={{
                                mt: 1,
                                mb: 1,
                                '& .MuiFormHelperText-root': {
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: '14px',
                                  color: '#004d40',
                                  fontWeight: 700
                                },

                                '& .MuiFormLabel-root': {
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: '16px',
                                  color: '#004d40'
                                },
                                '& .MuiInputBase-input': {
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: '18px',
                                  color: '#004d40',
                                  fontWeight: 700
                                },
                                '& label.Mui-focused': {
                                  fontSize: '16px',
                                  color: '#004d40',
                                  fontWeight: 700
                                },
                                '& .MuiInput-underline:after': {
                                  borderBottomColor: '#004d40',
                                },
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    fontFamily: '"Tajawal", sans-serif',
                                    fontSize: '16px',
                                    borderColor: '#004d40',
                                    borderWidth: "2px",
                                  },
                                  '&:hover fieldset': {
                                    borderColor: 'green',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: 'green',
                                  },
                                }
                              }}

                              fullWidth
                              InputLabelProps={{
                                style: {
                                  color: "#004d40",
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 18,
                                  fontWeight: 700,
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: {
                                  color: "#004d40",
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 18,
                                  fontWeight: 700,
                                },
                              }}

                            />
                          )}
                          renderOption={(props, option) => {
                            const { label, indexvalue } = option;
                            return (
                              <span
                                {...props}
                                style={{
                                  backgroundColor: indexvalue % 2 === 0 ? '#f2f9f2' : '#FFFFFF', // ألوان خلفية خضراء فاتحة ومنسجمة
                                  color: '#004D40', // لون النص داكن ليتناسب مع الخلفية الفاتحة
                                  fontFamily: '"Tajawal", sans-serif',
                                  fontSize: 18,
                                  fontWeight: 700,
                                }}
                              >
                                {indexvalue === -1 ? label : label}
                              </span>
                            );
                          }}
                        />


                 



              </CacheProvider>

            </Grid>
          </Grid>
        </Grid>

        <CacheProvider value={cacheRtl}>
          <ThemeProvider theme={theme_datagrid}>
            <DataGrid
              locale="ar-SD"
              rows={rows}
              columns={columns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              getRowId={(row) => row.id}
              getRowClassName={(params) =>
                parseInt(params.row.id_rows, 10) % 2 === 0 ? 'even-row' : 'odd-row'
              }

              slots={{
                toolbar: CustomToolbar,
              }}

              slotProps={{
                toolbar: { setRows, setRowModesModel, columns, handleExportExcel, handleAddRecord, customTextField_genNomUser, customTextField_genpw },
              }}

              sx={{
                boxShadow: 2,
                border: 2,

                borderColor: 'green', // Green border color
                overflowX: 'scroll',

                '& .MuiDataGrid-cell': {
                  fontFamily: 'Tajawal',
                  fontSize: '20px',

                  backgroundColor: 'rgba(255, 255, 255, 0.65)', // Keep the background color of cells as is
                  borderRight: '1px solid #ccc', // Keep the right border as is
                },
                '& .MuiDataGrid-row:hover': {
                  backgroundColor: '#004d40', // Green background color when row is hovered
                },

                '& .even-row': {
                  backgroundColor: '#e8f5e9', // Light green background color for even rows
                },
                '& .odd-row': {
                  backgroundColor: '#ffffff', // Keep the background color for odd rows as is
                },
              }}


              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }}
            />
          </ThemeProvider>
        </CacheProvider>

      </Box>
      <br />
      <Footer />

      <ConfirmationDialog open={isConfirmationOpen} onClose={handleConfirmationResponse} />
      <SaveConfirmationDialog open={isSaveConfirmationOpen} onClose={handleSaveConfirmationResponse} />

    </>
  );
}

export default SettingMfep;
